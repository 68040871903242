/* =WordPress Core - Sassified.
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

a {
  img {
    &.alignright {
      float: right;
      margin: 5px 0 20px 20px;
    }

    &.alignnone {
      margin: 5px 20px 20px 0;
    }

    &.alignleft {
      float: left;
      margin: 5px 20px 20px 0;
    }

    &.aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;

  &.alignnone {
    margin: 5px 20px 20px 0;
  }

  &.alignleft {
    margin: 5px 20px 20px 0;
  }

  &.alignright {
    margin: 5px 0 20px 20px;
  }

  img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
  }

  p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
  }
}

.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;

  img[class*="wp-image-"] {
    @include center-block;
  }

  .wp-caption-text {
    margin: 0.8075em 0;
  }
}

.wp-caption-text {
  text-align: center;
}

$columns: (
  1: 100%,
  2: 50%,
  3: 33.33%,
  4: 25%,
  5: 20%,
  6: 16.66%,
  7: 14.28%,
  8: 12.5%,
  9: 11.11%
);

$columns__margin: 3.8%;

.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;

  // Loops to enumerate the classes for gallery columns.
  @for $i from 2 through 9 {
    .gallery-columns-#{$i} & {
      max-width: map-get($columns, $i);
    }
  }
}

.gallery-caption {
  display: block;
}

.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
  display: inline-block;
}

ul,
ol {
  margin: 0;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
  font-size: 15px;
  th,
  tr,
  td {
    padding: 4px;
    border: 1px solid $text-color;
  }
}

p {
  margin-bottom: 1.5em;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

pre {
  background: $text-color;
  color: #fff;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  margin-bottom: 1.6em;
  line-height: 1.5;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 15px;
}

abbr,
acronym {
  border-bottom: 1px dotted $text-color;
  cursor: help;
}

mark,
ins {
  background: $bg-color;
  text-decoration: none;
}

big {
  font-size: 125%;
}

.sticky {
  display: block;
}

.post,
.page {
  // margin: 0 0 1.5em;
}

.updated:not(.published) {
  display: none;
}

.page-content,
.entry-content,
.entry-summary {
  margin: 1.5em 0 0;
}

.page-links {
  clear: both;
  margin: 0 0 1.5em;
}

figure {
  margin: 0;
}
