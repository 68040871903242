.hamburger {
  position: relative;
  z-index: 50;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid $text-color;
  width: 56px;
  height: 40px;
  border-radius: 3px;

  @media screen and (min-width: 1024px) {
    display: none;
  }

  span {
    position: relative;
    font-size: 0;
    width: 24px;
    height: 2px;
    display: block;
    background: #000;
    @include transition;

    &::before,
    &::after {
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      display: block;
      background: #000;
      content: "";
      @include transition;
    }

    &::before {
      top: -10px;
    }

    &::after {
      bottom: -10px;
      width: 100%;
      left: initial;
      right: 0;
    }
  }

  &.is-opened {
    z-index: 120;
    border: none;
    span {
      background-color: transparent;

      &::before {
        top: 0;
        transform: rotate(45deg);
        background: #fff;
      }

      &::after {
        bottom: 0;
        transform: rotate(-45deg);
        background: #fff;
      }
    }
  }
}
