.addthis_tool {
  margin-bottom: 35px;
  &:last-child {
    margin-bottom: 0;
  }
}

.at-svc-facebook {
  background: #1b408d !important;
}
.at-svc-twitter {
  background: #0094d6 !important;
}
.at-svc-whatsapp {
  background: #4fa13c !important;
}
.at-svc-email {
  background: #e64f36 !important;
}

.at-share-btn {
  border-radius: 4px !important;
  .at-label {
    font-family: "Muli" !important;
    font-weight: 600 !important;
    font-size: 12px !important;
  }
}
