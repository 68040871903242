// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: "Muli", "Helvetica Neue Light", "Helvetica Neue", "Helvetica",
  "Arial", sans-serif !default;

/// Copy text color
/// @type Color
$text-color: rgb(0, 36, 84) !default;

$title-color: rgb(83, 61, 228) !default;

$bg-color: rgb(187, 235, 219) !default;

/// Container's maximum width
/// @type Length
$max-width: 930px !default;


/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  "sm": only screen and
    (
      min-width: 768px
    ),
  "md": only screen and
    (
      min-width: 1024px
    ),
  "lg": only screen and
    (
      min-width: 1280px
    ),
  "xl": only screen and
    (
      min-width: 1440px
    ),
  "xxl": only screen and
    (
      min-width: 1920px
    )
) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: "/assets/" !default;
