.question-box {
  padding: 24px 20px;
  border: 4px solid $title-color;
  background: $title-color;
  font-size: 22px;
  font-weight: 900;
  min-height: 290px;
  // flex: 1;
  @include transition(background);
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 0 0 36px 0;

  @media screen and (min-width: 768px) {
    width: calc(50% - 30px);
    margin: 0 15px 36px 15px;
  }

  @media screen and (min-width: 1024px) {
    width: calc(33% - 27px);
  }

  a {
    display: flex;
    flex-direction: column;
    color: #fff;
    flex: 1;
  }

  &:hover {
    border: 4px solid $title-color;
    background: $bg-color;
    color: $title-color;
    a {
      color: $title-color;
    }
    .btn {
      background: $title-color;
      color: #fff;
    }
  }

  .btn {
    transition: none;
    align-self: flex-start;
    margin-top: auto;
  }
}
