.related-posts-holder {
  max-width: 770px;
  margin: 0 auto;
  margin-bottom: 24px;

  @media screen and (min-width: 1440px) {
    // max-width: 930px;
  }

  h3 {
    font-size: 16px;
    line-height: calc(24 / 16);
    margin-bottom: 16px;
  }
}
.related-posts {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  width: initial;
  .owl-stage {
    padding-left: 0 !important;
  }
  @media screen and (min-width: 768px) {
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 -15px;
  }

  .question-box {
    width: 100%;
    font-size: 18px;

    @media screen and (min-width: 768px) {
      width: calc(50% - 30px);
      margin: 0 15px 30px 15px;
      // margin-right: 25px;
    }
    @media screen and (min-width: 1024px) {
      width: calc(33% - 30px);
    }
  }
}
