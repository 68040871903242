.hero-banner {
  max-width: 540px;
  margin: 0 auto;
  padding-top: 60px;
  text-align: center;
  br {
    display: none;
  }
  @media screen and (min-width: 768px) {
    padding: 50px 0 30px;
  }
  h2 {
    font-size: 31px;
    line-height: 39px;
    color: $title-color;
    margin-bottom: 16px;
  }
}

.questions-holder {
  display: flex;
  flex-direction: column;

  margin-top: 30px;
  margin-bottom: 340px;
  padding-top: 244px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 214px;
    margin-left: -15px;
    margin-right: -15px;
    width: initial;
  }
}

.home-bottle-bg {
  height: 600px;
  position: fixed;
  width: 100vw;
  top: 385px;
  height: 600px;
  background-position: top center;
  z-index: 1;
  background-repeat: repeat-x;
  background-size: contain;
  background-position: center top;
  // height: 100vh;

  &:after {
    position: absolute;
    content: "";
    background: #4ec2ec;
    width: 100vw;
    height: 100vw;
    top: 350px;
  }
  @media screen and (min-width: 1025px) {
    top: 325px;
  }
}
