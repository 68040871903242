.single-article {
  padding-top: 60px;
  padding-bottom: 70px;
  max-width: 770px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
  // @media screen and (min-width: 1440px) {
  //   max-width: 930px;
  // }
}

.single-footer-holder {
  position: relative;
  z-index: 5;
  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 0;
    @media screen and (min-width: 768px) {
      flex-direction: row;
      padding: 0 20px 35px 20px;
    }
  }
}

.featured-post-image {
  margin-bottom: 44px;
}

.article-content {
  background: #fff;
  padding: 20px;
  @media screen and (min-width: 768px) {
    padding: 60px 80px 70px;
  }

  a {
    font-weight: 700;
    color: $text-color;
    text-decoration: underline;
    &:hover {
      color: $text-color;
    }
  }

  strong,
  b {
    font-weight: 800;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin: 0;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 24px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 16px;
  }

  p {
    font-size: 16px;
    line-height: calc(24 / 16);
    color: $text-color;
    margin-bottom: 20px;
    position: relative;
  }

  ul,
  ol {
    padding: 0px 0 18px 18px;
    margin: 0;
    font-size: 16px;
    line-height: 24px;

    @media screen and (min-width: 768px) {
      padding: 0px 0 36px 52px;
    }

    li {
      margin: 15px 0;
      line-height: 24px;
      ul {
        padding: 0px 0 18px 18px;
      }
    }
  }

  ul {
    list-style: none;

    li {
      position: relative;
      padding-left: 38px;
      &:before {
        background: $text-color;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        position: absolute;
        top: 8px;
        left: 0;
        overflow: hidden;
        content: "";
        margin-right: 24px;
      }

      ul {
        li {
          &:before {
            background: transparent;
            border: 1px solid $text-color;
          }
        }
      }
    }
  }

  ol {
    list-style-type: none;
    counter-reset: item;
    // margin: 0;
    padding: 0px 0 18px 18px;
    > li {
      display: block;
      margin-bottom: 12px;
      counter-increment: item;
      margin-bottom: 0.6em;

      &:before {
        content: counters(item, ".") ". ";
        padding-right: 0.6em;
        font-weight: 700;
      }

      > ol {
        padding: 0 0 18px 18px;
        margin-top: 12px;
        > li {
          // margin: 0;
          &:before {
            font-weight: 700;
            content: counters(item, ".") " ";
          }
        }
      }
    }
  }

  .fluid-img {
    width: 100%;
    height: auto;
  }

  blockquote {
    font-size: 20px;
    line-height: 31px;
    font-weight: 900;
    color: #188485;
    text-align: center;
    margin-bottom: 20px;
    p {
      color: inherit;
      margin: 0;
    }
    @media screen and (min-width: 768px) {
      padding: 20px 40px 40px;
    }
  }

  video {
    width: 100% !important;
    height: auto !important;
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    margin-bottom: 32px;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.question-holder {
  padding: 0 20px 20px 20px;

  @media screen and (min-width: 768px) {
    padding: 0px 80px 40px;
  }
  h1 {
    color: $text-color;
    @media screen and (min-width: 768px) {
      text-align: center;
    }
  }
  h2 {
    font-weight: 800;
    font-size: 20px;
    line-height: 31px;
    margin: 0;
    margin-bottom: 10px;
  }
  p {
    color: $text-color;
    font-weight: 400;
    font-size: 20px;
    line-height: 31px;
  }
  .question {
    margin-bottom: 30px;
    p {
      font-size: 32px;
      line-height: 40px;
      color: $text-color;
      font-weight: 900;

      @media screen and (min-width: 768px) {
        font-size: 28px;
        line-height: 35px;
      }
    }
  }
}
