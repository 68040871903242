// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}

body {
  max-width: 100%;
  overflow-x: hidden;
  background: $bg-color;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;

  margin: 0;
  padding: 0;
}

img {
  display: block;
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */
}

/**
 * Basic styles for links
 */
a {
  text-decoration: none;
  @include transition;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: 900;
  line-height: 1.25;
  color: $title-color;
}

p {
  font-size: 16px;
  color: $title-color;
  margin-bottom: 1.5rem;
}

main {
  position: relative;
  z-index: 2;
}

.page-404 {
  text-align: center;
  p:last-child {
    margin-bottom: 0;
  }
}
