// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn {
  display: inline-block;
  background: $title-color;
  color: #fff;
  padding: 10px 24px;
  font-size: 16px;
  border-radius: 4px;
  &.btn-white {
    background: #fff;
    color: $title-color;
  }
  &:hover {
    background: $text-color;
  }
}
