#cookie-notice {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  .cookie-notice-container {
    display: flex;
    width: initial;
    max-width: $max-width;
    margin: 0 auto;
    flex-direction: column;
    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
  }
  .cn-text-container {
    a {
      color: #5c6167;
      text-decoration: underline;
      &:hover {
        color: $title-color;
      }
    }
    margin-bottom: 12px;
    @media screen and (min-width: 1024px) {
      text-align: left;
      margin-bottom: 0;
    }
  }
  .cn-button {
    font-size: 16px;
    letter-spacing: normal;
    font-family: inherit;
    font-weight: 700;
    background: #279fc4;
    color: #fff;
    border: none;
    border-radius: 4px;
    text-shadow: none;
    padding: 12px 50px;

    &:hover {
      background: $title-color;
    }

    @media screen and (min-width: 1024px) {
      margin-left: 32px;
    }
  }
}
