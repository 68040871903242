.cta-holder {
  padding: 15px;
  background: #188485;
  color: #fff;
  font-size: 24px;
  line-height: calc(30 / 24);

  a {
    text-decoration: underline;
    color: #fff !important;
  }
  .cta-logo-holder {
    display: flex;
    flex-direction: column;
    img {
      margin-top: 30px;
      align-self: flex-end;
    }
  }
}
