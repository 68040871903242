/**
 * Basic typography style for copy text
 */

html {
  // This defines what 1 rem is
  font-size: 62.5%;
}

body {
  color: $text-color;
  font: normal 125% / 1.4 $text-font-stack;
}
