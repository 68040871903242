//about page
body.page-id-1923 {
  &:before {
    height: 350px !important;
  }
  &:after {
    height: 540px !important;
  }
}

body.error404,
body.page:not(.page-template-homepage),
body.single {
  background: #f7f7f7;
  position: relative;
  overflow-x: hidden;
  &:after,
  &:before {
    position: absolute;
    content: "";
    display: block;
    height: 100vh;
    width: 100vw;
    background: $bg-color;
    z-index: 1;

    @media screen and (min-width: 320px) {
      height: 800px;
    }
    @media screen and (min-width: 768px) {
      height: 600px;
    }
  }
  &:after {
    bottom: 0;
    height: 930px;
  }

  header,
  main,
  .single-footer-holder {
    position: relative;
    z-index: 3;
  }

  header {
    z-index: 100;
  }

  .single-footer-holder {
    padding-top: 135px;
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    @media screen and (min-width: 768px) {
      background-size: contain;
      padding-top: 220px;
      background-repeat: repeat-x;
    }
  }
}

body.page:not(.page-template-homepage) {
  &:before {
    height: 450px;
  }

  &:after {
    height: 750px;
  }
}
