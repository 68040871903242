// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer {
  max-width: $max-width;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 28px;
}

.footer-social-account {
  display: flex;
  list-style: none;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      display: block;
      .hover_logo {
        display: none;
      }
      &:hover {
        img {
          display: none;
        }
        .hover_logo {
          display: block;
        }
      }
    }
    margin-right: 15px;
    &:last-child {
      margin: 0;
    }
  }
}

.footer-logo {
  font-size: 20px;
  color: $text-color;
  font-weight: 900;
  margin-bottom: 12px;
  @media screen and (min-width: 768px) {
    margin: 0;
  }
}

.copy-right {
  background: $text-color;
  padding: 24px 0;
}
.copy-right-content {
  font-size: 13px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }

  > div {
    margin: 12px 0;
    width: 100%;
    display: flex;
    @media screen and (min-width: 768px) {
      width: 33%;
    }
  }

  > div:nth-child(3) {
    justify-content: center;
    @media screen and (min-width: 768px) {
      justify-content: flex-end;
    }
  }
}

.footer-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  img {
    margin-bottom: -3px;
    margin-left: 3px;
  }
}

.footer-menu {
  display: inline-flex;
  margin: 0 auto;
  list-style: none;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 768px) {
    align-items: flex-start;
    margin: 0;
  }


  li {
    margin: 3px 0;
  }
  a {
    color: #fff;
    &:hover {
      text-decoration: underline;
      color: $bg-color;
    }
  }
}
