.social-share {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 41px;
  @media screen and (min-width: 768px) {
    margin-bottom: 27px;
  }

  a {
    font-family: $text-font-stack;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    padding: 7px 10px;
    color: #fff;

    text-decoration: none;
    border-radius: 2px;
    letter-spacing: 0.2;
    display: flex;
    align-items: center;
    // min-width: 70px;
    margin-right: 5px;
    margin-bottom: 5px;

    @media screen and (min-width: 768px) {
      margin-right: 10px;
    }
    img {
      margin-right: 5px;
    }

    &:hover {
      color: #fff;
      opacity: 0.9;
    }
    &:last-child {
      margin-right: 0;
    }
    &.facebook {
      background: #1b408d;
      // min-width: 85px;
      img {
        height: 13px;
        width: auto;
      }
    }
    &.twitter {
      background: #0094d6;
      // min-width: 80px;
      img {
        width: 14px;
        height: auto;
      }
    }
    &.whatsapp {
      background: #4fa13c;
      img {
        width: 16px;
        height: auto;
      }
    }
    &.email {
      background: #e64f36;
      img {
        width: 15px;
        height: auto;
      }
    }
  }
}
