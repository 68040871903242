// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.page-header {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 25px;
  line-height: 31px;
  font-weight: 900;

  @media screen and (min-width: 1024px) {
    padding-top: 36px;
    font-size: 29px;
    line-height: 36px;
  }

  .logo {
    a {
      color: $text-color;
      h1 {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
      }
    }

    .tagline {
      display: flex;
      flex-direction: row;
      font-size: 13px;
      line-height: initial;
      font-weight: 400;
      align-items: flex-end;
      img {
        margin-left: 8px;
        margin-bottom: -2px;
      }
    }
  }
}

.header-menu {
  display: flex;

  ul {
    list-style: none;
    display: flex;
  }

  a {
    color: $text-color;
    font-size: 15px;
    line-height: 15px;
    font-weight: 900;
    @media screen and (max-width: 360px) {
      font-size: 24px !important;
    }
    &:hover {
      color: $title-color;
    }
  }

  @media screen and (max-width: 1023px) {
    left: 100%;
    transition: 300ms cubic-bezier(0.47, 0, 0.745, 0.715) left;
    position: fixed;
    left: 100%;
    top: 0;
    background: $title-color;
    display: block;
    width: 100vw;
    height: 100vh;
    z-index: 100;

    ul {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      li {
        margin: 0;
        line-height: 80px;
        a {
          font-size: 30px;
          color: #fff;
          margin: 0;
        }
      }
    }

    &.is-active {
      left: 0;
    }
  }



  @media screen and (min-width: 1024px) {
    ul {
      li {
        margin-right: 23px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
